// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-an-appointment-js": () => import("./../src/pages/book-an-appointment.js" /* webpackChunkName: "component---src-pages-book-an-appointment-js" */),
  "component---src-pages-counselling-js": () => import("./../src/pages/counselling.js" /* webpackChunkName: "component---src-pages-counselling-js" */),
  "component---src-pages-end-of-life-doula-js": () => import("./../src/pages/end-of-life-doula.js" /* webpackChunkName: "component---src-pages-end-of-life-doula-js" */),
  "component---src-pages-faqs-js": () => import("./../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-supervision-js": () => import("./../src/pages/supervision.js" /* webpackChunkName: "component---src-pages-supervision-js" */)
}

